import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BsFileEarmarkPdf, BsPencilSquare } from "react-icons/bs";
import { NavLink, useNavigate } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { BsPlusSquareFill } from "react-icons/bs";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useStateContext } from "../../context/ContextProvider";
import { useDownloadExcel } from "react-export-table-to-excel";
import { FaRegEye } from "react-icons/fa";
import { RiFileExcel2Line } from "react-icons/ri";

const TableUserPelamar = () => {
	const { currentColor, currentMode, token } = useStateContext();
	const [loading, setLoading] = useState(false);
	const [limit, setLimit] = useState(30);
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [search, setSearch] = useState("");
	const [idEdit, setIdEdit] = useState(null);
	const [edit, setEdit] = useState({
		email: "",
		activated: false,
	});
	const [active, setActive] = useState("false");
	let [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState();
	const [total, setTotal] = useState();
	const navigate = useNavigate();
	const pageNumberLimit = 5;
	const [maxPageLimit, setMaxPageLimit] = useState(5);
	const [minPageLimit, setMinPageLimit] = useState(0);
	const [create, setCreate] = useState([]);
	const [panjang, setPanjang] = useState();
	let [abj, setAbj] = useState("");
	let [state, setState] = useState();
	const [allPosisi, setAllPosisi] = useState([]);
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};
	const onPrevClick = () => {
		if ((currentPage - 1) % pageNumberLimit === 0) {
			setMaxPageLimit(maxPageLimit - pageNumberLimit);
			setMinPageLimit(minPageLimit - pageNumberLimit);
		}
		setCurrentPage((prev) => prev - 1);
	};

	const onNextClick = () => {
		if (currentPage + 1 > maxPageLimit) {
			setMaxPageLimit(maxPageLimit + pageNumberLimit);
			setMinPageLimit(minPageLimit + pageNumberLimit);
		}
		setCurrentPage((prev) => prev + 1);
	};

	const getData = async () => {
		setLoading(true);
		const response = await axios.get(
			`https://api.ctk-kiba.com/api/ctk/data?s=${search}&page=${currentPage}&limit=${limit}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		setData(response.data.all);
		setPanjang(response.data.all.length);
		setCreate(
			response.data.all.map((item) => {
				const date = new Date(item.created_at);
				date.setMonth(date.getMonth() - 6);
				const bulan = date.toString().slice(3, 15);
				setAbj(bulan);
				return bulan;
			})
		);
		setTotal(response.data.total);
		setTotalPage(response.data.totalPage);
		setLoading(false);
	};

	const pages = [];
	for (let i = 1; i <= totalPage; i++) {
		pages.push(i);
	}

	const deleteUser = async (id) => {
		try {
			await axios.delete(`https://api.ctk-kiba.com/api/ctk/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			getData();
		} catch (error) {
			console.log(error);
		}
	};

	const getPosisi = async () => {
		const response = await axios.get(
			"https://api.ctk-kiba.com/api/posisi/public",
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		setAllPosisi(response.data);
	};

	const findOine = async () => {
		const res = await axios.get(`https://api.ctk-kiba.com/api/ctk/${idEdit}`);
		setActive(res.data.activated);
		setEdit({
			email: res.data.email,
		});
	};

	const editPelamar = async () => {
		try {
			const edi = await axios.patch(
				`https://api.ctk-kiba.com/api/ctk/${idEdit}`,
				{
					activated: active === "true" ? true : false,
					email: edit.email,
				}
			);
			getData();
			if (edi.status === 200) {
				setOpen(false);
				// setEdit({ email: "", activated: false });
				// getData();
			}
		} catch (error) {
			console.log(error);
		}
	};

	const change = (e) => {
		const fieldName = e.target.name;
		setEdit((exist) => ({
			...exist,
			[fieldName]: e.target.value,
		}));
	};

	React.useEffect(() => {
		getPosisi();
		if (token) {
			getData();
		}
	}, [search, currentPage]);

	useEffect(() => {
		if (idEdit !== null) {
			findOine();
		}
	}, [idEdit]);

	console.log(typeof active);

	const tableRef = useRef(null);
	const { onDownload } = useDownloadExcel({
		currentTableRef: tableRef.current,
		filename: "Data Pelamar",
		sheet: "Users",
	});

	return (
		<>
			<div className="sm:flex md:mb-4 lg:mb-4 sm:justify-between sm:items-center ">
				<div className="flex justify-between items-center">
					<div className="items-center flex justify-center ">
						<div className="input-group relative flex gap-3 items-stretch w-full ">
							<input
								type="search"
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								placeholder="Search"
								aria-label="Search"
								aria-describedby="button-addon2"
							/>
							<button
								style={{ backgroundColor: currentColor }}
								className="btn px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center"
								type="submit"
								id="button-addon2">
								<svg
									aria-hidden="true"
									focusable="false"
									data-prefix="fas"
									data-icon="search"
									className="w-4"
									role="img"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 512 512">
									<path
										fill="currentColor"
										d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
									/>
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div>
			{loading ? (
				<div
					role="status"
					className="w-full h-[60%] flex justify-center items-center">
					<svg
						aria-hidden="true"
						className="mr-2 w-52 h-52 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
						viewBox="0 0 100 101"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
							fill="currentColor"
						/>
						<path
							d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
							fill="currentFill"
						/>
					</svg>
					<span className="sr-only">Loading...</span>
				</div>
			) : (
				<div className="flex flex-col">
					<div className=" overflow-auto sm:mx-0.5 lg:mx-0">
						<div className="py-2 inline-block min-w-full sm:px-6 lg:px-0 md:px-0">
							<div className="overflow-hidden">
								<table className="min-w-full" ref={tableRef}>
									<thead className="bg-gray-200 border-b">
										<tr>
											<th
												scope="col"
												className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
												No
											</th>
											<th
												scope="col"
												className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
												NIK
											</th>
											<th
												scope="col"
												className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
												Email
											</th>
											<th
												scope="col"
												className="text-sm font-medium text-center text-gray-900 px-6 py-4  whitespace-nowrap">
												Status Aktivasi
											</th>
											<th
												scope="col"
												className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
												Actions
											</th>
										</tr>
									</thead>
									<tbody className="uppercase">
										{data.map((item, index, buat) => (
											<tr
												key={item.id}
												className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
												<td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
													{index + 1}
												</td>
												<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
													{item.nik}
												</td>
												<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
													{item.email}
												</td>
												<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
													{item.activated ? "Aktif" : "Belum Aktif"}
												</td>
												<td className="text-sm text-gray-900 text-center font-light px-2 py-4">
													<div className="flex justify-center items-center gap-5">
														<TooltipComponent
															content="Hapus"
															position="TopCenter">
															<span
																className="hover:cursor-pointer"
																onClick={() => {
																	if (
																		window.confirm(
																			"Apakah Anda Yakin Ingin Menghapus Anggota?"
																		)
																	)
																		deleteUser(item.id);
																}}>
																<MdOutlineDeleteOutline color="red" />
															</span>
														</TooltipComponent>
														<TooltipComponent
															content="Edit"
															position="TopCenter">
															<span
																className="hover:cursor-pointer"
																onClick={() => {
																	setIdEdit(item.id);
																	setOpen(true);
																}}>
																<BiEdit color="green" />
															</span>
														</TooltipComponent>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			)}
			{open ? (
				<>
					<div className="justify-center text-left items-center md:p-0 lg:p-0 p-5 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-auto my-6 mx-auto max-w-3xl">
							{/*content*/}
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								{/*header*/}
								<div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
									<h3 className="text-3xl text-green-400 text-center font-semibold">
										PT Bantaeng Sinergi Cemerlang
									</h3>
									<button
										className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
										onClick={() => setOpen(false)}>
										<span className=" bg-red-400 text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
											×
										</span>
									</button>
								</div>
								{/*body*/}
								<div className="relative p-6 flex-auto">
									<div className="flex flex-wrap -mx-3 mb-6">
										<div className="w-full px-3">
											<label
												className={`block uppercase tracking-wide text-xs font-bold text-gray-700 mb-2`}
												htmlFor="status_test">
												Status Aktivasi
											</label>
											<div className="relative">
												<select
													onChange={(e) => setActive(e.target.value)}
													value={active}
													name="activated"
													className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
													id="status_test">
													<option>- - - Status Aktivasi - - -</option>
													<option value="true">Aktif</option>
													<option value="false">Non Aktif</option>
												</select>
											</div>
										</div>
									</div>
									<div className="flex flex-wrap -mx-3 mb-6">
										<div className="w-full px-3">
											<label
												className={`block uppercase tracking-wide text-xs font-bold text-gray-700 mb-2`}
												htmlFor="alasan_test">
												Email
											</label>
											<input
												className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
												id="alasan_test"
												type="text"
												onChange={change}
												value={edit.email}
												name="alasan_test"
												placeholder="Alasan Lulus/Tidak Lulus"
											/>
										</div>
									</div>
								</div>
								{/*footer*/}
								<div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
									<button
										className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
										type="button"
										onClick={() => setOpen(false)}>
										Close
									</button>
									<button
										className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
										type="button"
										onClick={() => editPelamar()}>
										Save Changes
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : (
				" "
			)}
			<div>
				<div className="mb-5 flex m-auto justify-start text-sm md:mt-12 mt-8 items-center">
					<span className={`${currentMode === "Dark" ? "text-white " : ""}`}>
						Total Data Pelamar
						<span className="font-semibold" style={{ color: currentColor }}>
							{total}
						</span>
					</span>
				</div>
				<div className="flex m-auto justify-start text-sm items-center">
					<span className={`${currentMode === "Dark" ? "text-white " : ""}`}>
						Total Halaman
						<span className="font-semibold" style={{ color: currentColor }}>
							{totalPage}
						</span>
					</span>
				</div>
			</div>
			<div className="flex m-auto overflow-scroll justify-center md:mt-12 mt-8 items-center ">
				<ul className="inline-flex w-full -space-x-px">
					<li>
						<a
							onClick={onPrevClick}
							className={` ${
								currentPage === pages[0] && "hidden"
							} px-3 py-2 leading-tight text-gray-500 hover:cursor-pointer bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}>
							Previous
						</a>
					</li>
					{pages.map((item, index) => (
						<li key={item}>
							<span
								onClick={() => setCurrentPage((currentPage = item))}
								className={`${
									currentPage === item
										? `bg-[${currentColor}] hover:cursor-pointer px-3 py-2 leading-tight text-white`
										: "px-3 py-2 text-gray-500 hover:cursor-pointer border border-gray-300"
								}`}>
								{item}
							</span>
						</li>
					))}
					<li>
						<a
							onClick={onNextClick}
							className={` ${
								currentPage === pages[pages.length - 1] && "hidden"
							} px-3 py-2 leading-tight text-gray-500 hover:cursor-pointer bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}>
							Next
						</a>
					</li>
				</ul>
			</div>
		</>
	);
};

export default TableUserPelamar;
