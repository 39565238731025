import React, { useEffect } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { BsCheck } from "react-icons/bs";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { themeColors } from "../data/dummy";
import { useStateContext } from "../context/ContextProvider";
import { MdNightsStay } from "react-icons/md";

const ThemeSettings = () => {
	const {
		setColor,
		setMode,
		currentMode,
		setcurrentMode,
		currentColor,
		setThemeSettings,
	} = useStateContext();

	// Aktifkan tutup otomatis jika lebih dari 10detik
	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setThemeSettings(false);
	// 	}, 10000);
	// }, []);

	return (
		<div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
			<div
				className={
					currentMode === "Dark"
						? "float-right h-screen bg-main-dark-bg dark:text-gray-200 dark:[#484B52] w-400}"
						: "float-right h-screen bg-main-bg dark:text-gray-200 dark:[#484B52] w-400}"
				}>
				<div
					className="flex items-center justify-between drop-shadow-lg p-4 m-4"
					style={{ backgroundColor: currentColor }}>
					<p className="text-lg text-white font-semibold">
						Atur Warna Dan Tema
					</p>
					<TooltipComponent content="Close" position="BottomCenter">
						<button
							type="button"
							onClick={() => setThemeSettings(false)}
							style={{ color: "white" }}
							className="text-2xl p-3 hover:drop-shadow-xl rounded-[50%] hover:bg-gray-200">
							<MdOutlineCancel />
						</button>
					</TooltipComponent>
				</div>
				<div className="flex-col border-color p-4 ml-4">
					<p className="font-semibold text-lg">Tema</p>

					{/* Switch Theme */}
					<div className="flex items-center pt-5 justify-start">
						<label
							htmlFor="checked-toggle"
							className="inline-flex relative items-center cursor-pointer">
							<input
								type="checkbox"
								id="checked-toggle"
								onChange={setMode}
								value={currentMode === "Light" ? "Dark" : "Light"}
								className="sr-only peer"
							/>
							<div
								className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
								onClick={() => setcurrentMode(currentMode === "Dark")}
							/>
							<span className="ml-2">
								<MdNightsStay />
							</span>
						</label>
					</div>
					<div>
						<div className="flex items-center mt-4">
							<div className="bg-[#054A91] w-[16%] h-[2px]"></div>
							<div className="bg-[#70EE9C] w-[16%] h-[2px]"></div>
							<div className="bg-[#db00ef] w-[16%] h-[2px]"></div>
							<div className="bg-[#E71D36] w-[16%] h-[2px]"></div>
							<div className="bg-[#E7BB41] w-[16%] h-[2px]"></div>
							<div className="bg-[#FB9678] w-[16%] h-[2px]"></div>
						</div>
					</div>
				</div>
				<div className="flex-col border-color p-4 ml-4">
					<p className="font-semibold text-lg">Pilih Warna</p>
					<div className="gap-3 flex">
						{themeColors.map((color, index) => (
							<TooltipComponent
								key={index}
								content={color.name}
								position="TopCenter">
								<div className="relative gap-5 flex cursor-pointer items-center">
									<button
										type="button"
										className="rounded-full w-12 h-12 flex hover:drop-shadow-2xl items-center justify-center cursor-pointer"
										style={{ backgroundColor: color.color }}
										onClick={() => setColor(color.color)}>
										<BsCheck
											className={`text-2xl text-white ${
												color.color === currentColor ? "block" : "hidden"
											}`}
										/>
									</button>
								</div>
							</TooltipComponent>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ThemeSettings;
