import React, { useEffect, useState } from 'react';
import { useStateContext } from '../context/ContextProvider';
import Children from '../layout/Children';
import CardWelder from '../components/card/CardWelder';
import { Dashboard1 } from '../components/charts/Dashboard1';
import { Footer, Header } from '../components';
import Dashboard3 from '../components/charts/Dashboard3';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Line } from '../pages';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { GiMiningHelmet } from 'react-icons/gi';
import { DashboardGender } from '../components/charts/DashboardGender';
import CardLulusKerja from '../components/card/CardLulusKerja';

const Ecommerce = () => {
  const { currentColor, currentMode, handleClick, roles, token } =
    useStateContext();
  let [state, setState] = useState('');
  const [data, setData] = useState([]);
  const getData = async () => {
    const response = await axios.get('https://api.ctk-kiba.com/api/posisi', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setData(response.data);
  };
  const navigate = useNavigate();
  useEffect(() => {
    getData();
    if (!token) {
      navigate('/');
    }
    if (roles !== 'admin') {
      navigate('/');
    }
  }, []);

  return (
    <>
      <Children>
        <div className="md:mt-12 lg:mt-12 md:ml-9 p-5 min-h-screen">
          <div className="mb-12">
            <Header
              category="Halaman"
              title="Persentase Keseluruhan Calon Tenaga Kerja"
            />
          </div>
          <div className="flex mb-12 flex-wrap md:flex-nowrap lg:flex-nowrap gap-10 justify-center">
            <Dashboard1 />
            <Dashboard3 />
          </div>
          <div className="flex mb-12 flex-wrap md:flex-nowrap lg:flex-nowrap gap-10 justify-center">
            <DashboardGender />
          </div>
          <div className="mt-16 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4">
            <CardLulusKerja />
            <CardWelder />
            {data.map((item, index) => (
              <div
                key={index}
                onClick={() =>
                  setState(
                    (state = item.nama_posisi),
                    navigate('/tabledimanis', {
                      state: {
                        nama_posisi: state,
                      },
                    }),
                  )
                }
                className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md"
              >
                <div className="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-blue-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                    className="w-6 h-6 text-white"
                  >
                    <GiMiningHelmet />
                  </svg>
                </div>
                <div className="p-4 text-right">
                  <p className="block antialiased font-sans leading-normal font-semibold text-blue-600">
                    <span className="text-black font-bold">
                      {item.nama_posisi.toUpperCase()}
                    </span>
                  </p>
                  <span className="text-blue-500 tracking-normal font-sans text-lg font-bold leading-snug text-blue-gray-900">
                    {item.pelamarCount} Pelamar
                  </span>
                </div>
                <div
                  onClick={() => setState((state = item.nama_posisi))}
                  className="border-t hover:cursor-pointer border-blue-gray-50 p-4"
                >
                  <div className="flex justify-between items-center ">
                    <p className="block antialiased font-sans text-base leading-relaxed font-bold text-blue-gray-600">
                      Lihat Data
                    </p>
                    <AiOutlineArrowRight />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="ml-5">
            <Footer />
          </div>
        </div>
      </Children>
    </>
  );
};

export default Ecommerce;
