import React, { useEffect } from "react";
import { Footer, Header } from "../components";
import { Link, useNavigate } from "react-router-dom";
import Children from "../layout/Children";
import FormInputPelamar from "../components/form/FormInputPelamar";
import { useStateContext } from "../context/ContextProvider";

const onChange = (e) => {
	document.getElementById("preview").style.backgroundColor = e.currentValue.hex;
};

const ColorPicker = () => {
	const { currentMode, roles, token } = useStateContext();
	const navigate = useNavigate();

	useEffect(() => {
		if (!token) {
			navigate("/");
		}
		if (roles !== "admin") {
			navigate("/");
		}
	}, []);
	return (
		<Children>
			<div className=" md:m-10 min-h-screen p-5 bg-white rounded-xl dark:bg-secondary-dark-bg">
				<Header category="Form" title="Tambah Pelamar" />
				<FormInputPelamar />
				<Footer />
			</div>
		</Children>
	);
};

export default ColorPicker;
