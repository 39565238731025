import React from "react";
import { useStateContext } from "../context/ContextProvider";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { FiSettings } from "react-icons/fi";
import { Sidebar, ThemeSettings, Navbar } from "../components";
const Children = ({ children }) => {
	const {
		activeMenu,
		themeSettings,
		setThemeSettings,
		currentMode,
		currentColor,
	} = useStateContext();

	return (
		<div>
			<div
				className={
					currentMode === "Dark" ? "dark bg-main-dark-bg" : "bg-main-bg"
				}>
				<div className="flex relative dark:bg-main-dark-bg">
					<div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
						<TooltipComponent content="Settings" position="TopCenter">
							<button
								onClick={() => setThemeSettings(true)}
								type="button"
								className="text-3xl p-3 hover:drop-shadow-xl bg-blue-500 rounded-full hover:scale-110 hover:bg-light-gray text-white"
								style={{ background: currentColor, borderRadius: "50%" }}>
								<FiSettings />
							</button>
						</TooltipComponent>
					</div>
					{activeMenu ? (
						<div
							className="fixed w-72 lg:w-80 bg-white  sidebar dark:bg-secondary-dark-bg
						">
							<Sidebar />
						</div>
					) : (
						<div className="w-0 bg-main-dark-bg">Close Sidebar</div>
					)}
					<div
						className={`dark:bg-main-dark-bg overflow-hidden bg-main-bg w-full ${
							activeMenu ? "md:ml-72" : "flex-2"
						}`}>
						<div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
							<Navbar />
						</div>
						<div className="">{themeSettings && <ThemeSettings />}</div>
						{children}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Children;
