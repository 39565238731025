import React, { useEffect, useState } from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useStateContext } from "../../context/ContextProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: "top",
		},
		title: {
			display: true,
			text: "PT Bantaeng Sinergi Cemerlang",
		},
	},
};

export const Dashboard1 = () => {
	const [posisi, setPosisi] = useState([]);
	const [data, setData] = useState([]);
	const navigate = useNavigate();
	const { token, currentMode, roles } = useStateContext();

	const getData = async () => {
		const response = await axios.get("https://api.ctk-kiba.com/api/posisi");
		setData(response.data);
		setPosisi(response.data.length);
	};

	let pos = [];
	let pelamar = [];
	const nama_posisi = [];
	data.map((item) => {
		pos.push(item.nama_posisi);
		pelamar.push(item.pelamarCount);
		nama_posisi.push(item.nama_posisi);
	});

	useEffect(() => {
		if (!token) {
			navigate("/");
		}
		if (roles !== "admin") {
			navigate("/");
		}
		getData();
	}, []);

	const labels = pos;
	const bg = [
		"#004E98",
		"#BFCC94",
		"#7A3B69",
		"#197278",
		"#EE4266",
		"#07A0C3",
		"#F0C808",
		"#086788",
		"#E28413",
		"#DE3C4B",
		"#004E98",
		"#BFCC94",
		"#7A3B69",
		"#197278",
		"#EE4266",
		"#07A0C3",
		"#F0C808",
		"#086788",
		"#E28413",
		"#DE3C4B",
		"#004E98",
		"#BFCC94",
		"#7A3B69",
		"#197278",
		"#EE4266",
		"#07A0C3",
		"#F0C808",
		"#086788",
		"#E28413",
		"#DE3C4B",
	];

	const datas = {
		labels,
		datasets: [
			{
				label: pelamar,
				data: pelamar,
				backgroundColor: bg,
			},
		],
	};

	return (
		<div
			className={`flex justify-center rounded-xl ${
				currentMode === "Dark" ? "bg-secondary-dark-bg" : "bg-gray-50"
			}  drop-shadow-xl items-center p-7 w-full`}>
			<Bar options={options} data={datas} />
		</div>
	);
};
