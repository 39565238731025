import React, { useEffect, useState } from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useStateContext } from "../../context/ContextProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: "top",
		},
		title: {
			display: true,
			text: "Data Pelamar Berdasarkan Jenis Kelamin",
		},
	},
};

export const DashboardGender = () => {
	const [posisi, setPosisi] = useState([]);
	const [female, setFemale] = useState();
	const [male, setMale] = useState();
	const [data, setData] = useState([]);
	const navigate = useNavigate();
	const { token, currentMode, roles } = useStateContext();

	const getData = async () => {
		const response = await axios.get(
			"https://api.ctk-kiba.com/api/pelamar/female"
		);
		setFemale(response.data);
	};

	const getDataMale = async () => {
		const response = await axios.get(
			"https://api.ctk-kiba.com/api/pelamar/male"
		);
		setMale(response.data);
	};

	useEffect(() => {
		if (!token) {
			navigate("/");
		}
		if (roles !== "admin") {
			navigate("/");
		}
		getData();
		getDataMale();
	}, []);

	const labels = ["Perempuan", "Laki-Laki"];
	const datas = {
		labels,
		datasets: [
			{
				label: ["Perempuan", "Laki-Laki"],
				data: [female, male],
				backgroundColor: ["rgb(255, 125, 153)", "rgb(255, 1, 56)"],
			},
		],
	};

	return (
		<div
			className={`flex justify-center rounded-xl ${
				currentMode === "Dark" ? "bg-secondary-dark-bg" : "bg-gray-50"
			}  drop-shadow-xl items-center p-7 w-full`}>
			<Bar options={options} data={datas} />
		</div>
	);
};
