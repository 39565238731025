import React, { useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { FiShoppingCart } from "react-icons/fi";
import { BsChatLeft } from "react-icons/bs";
import { RiNotification3Line } from "react-icons/ri";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import avatar from "../data/foto.JPG";
import { Cart, Chat, Notification, UserProfile } from ".";
import { useStateContext } from "../context/ContextProvider";
import Cookies from "js-cookie";

const NavButton = ({ title, costumFunc, icon, color, dotColor, position }) => {
	return (
		<TooltipComponent content={title} position={position}>
			<button
				type="button"
				onClick={costumFunc}
				style={{ color }}
				className="relative text-xl rounded-full p-3 hover:bg-light-gray">
				<span
					style={{ background: dotColor }}
					className="absolute rounded-full w-2 h-2 inline-flex right-2 top-2"
				/>
				{icon}
			</button>
		</TooltipComponent>
	);
};

const Navbar = () => {
	const {
		activeMenu,
		setActiveMenu,
		isClicked,
		handleClick,
		screenSize,
		setScreenSize,
		currentColor,
	} = useStateContext();
	const name = Cookies.get("username");
	useEffect(() => {
		const handleResize = () => setScreenSize(window.innerWidth);
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		if (screenSize <= 900) {
			setActiveMenu(false);
		} else {
			setActiveMenu(true);
		}
	}, [screenSize]);

	return (
		<>
			<div className="flex z-auto justify-between drop-shadow-xl p-2 md:mx-6 relative">
				<NavButton
					title="Menu"
					position="BottomCenter"
					costumFunc={() => setActiveMenu(!activeMenu)}
					color={currentColor}
					icon={<AiOutlineMenu />}
				/>
				<div className="flex gap-5 items-center mt-3">
					{isClicked.userProfile && <UserProfile />}
					<TooltipComponent content="User Profile" position="BottomCenter">
						<div
							className="flex items-center gap-1 cursor-pointer p-2 hover:bg-light-gray rounded-lg"
							onClick={() => handleClick("userProfile")}>
							<img src={avatar} className="rounded-full h-5 w-5" />
							<p>
								<span className="text-gray-400 text-14">Halo</span>
								<span className="text-gray-400 ml-1 font-bold text-14">
									{name}
								</span>
							</p>
							{isClicked.userProfile ? (
								<MdKeyboardArrowUp />
							) : (
								<MdKeyboardArrowDown />
							)}
						</div>
					</TooltipComponent>
				</div>
			</div>
		</>
	);
};

export default Navbar;
