import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../context/ContextProvider";
import logo from "../../data/huadi.png";

const Stacked = () => {
	const { currentColor, currentMode, handleClick, roles, token } =
		useStateContext();
	const [loading, setLoading] = useState(false);
	const [msg, setMsg] = useState("");
	const [posisi, setPosisi] = useState([]);
	const [open, setOpen] = useState(false);
	const [perusahaan, setPerusahaan] = useState([]);
	const [idPerusahaan,setIdPerusahaan]=useState(null)
	let [data, setData] = useState({
		perusahaan: "",
		nama_posisi: "",
		kebutuhan_karyawan: "",
		kualifikasi: "",
		kualifikasi2: "",
		kualifikasi3: "",
		kualifikasi4: "",
		kualifikasi5: "",
		persyaratan_umum: "",
		persyaratan_umum2: "",
		persyaratan_umum3: "",
		persyaratan_umum4: "",
		test_tertulis_gelombang1: "",
		test_wawancara_gelombang1: "",
		test_lapangan_gelombang1: "",
		test_tertulis_gelombang2: "",
		test_wawancara_gelombang2: "",
		test_lapangan_gelombang2: "",
		test_tertulis_gelombang3: "",
		test_wawancara_gelombang3: "",
		test_lapangan_gelombang3: "",
		pengumuman_seleksi: "",
	});

	const getPerusahaan = async () => {
		const response = await axios.get(
			"https://api.ctk-kiba.com/api/perusahaan/public"
		);
		setPerusahaan(response.data);
	};

	const navigate = useNavigate();
	const getPosisi = async () => {
		const response = await axios.get(
			"https://api.ctk-kiba.com/api/posisi/public"
		);
		setPosisi(response.data);
	};

	const change = (e) => {
		const fieldName = e.target.name;
		setData((exist) => ({
			...exist,
			[fieldName]: e.target.value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			await axios
				.post("https://api.ctk-kiba.com/api/history", {
					...data,
				})
				.then((response) => {
					if (response.status === 201) {
						setOpen(true);					
					} else {
						navigate("/stacked");
					}
				});
			setOpen(true);
			setLoading(false);
		} catch (error) {}
	};

	useEffect(() => {
		getPosisi();
		getPerusahaan();
		if (!token) {
			navigate("/");
		}
		if (roles !== "tamu") {
			navigate("/");
		}
	}, []);

	return (
		<>
			<div className="bg-hero-pattern bg-cover bg-center">
				<div className="flex justify-center items-center">
					<img src={logo} alt="logo" />
				</div>
				<div className="flex justify-center items-center">
					<span className="text-center font-extrabold text-2xl">
						Form Permintaan Calon Tenaga Kerja PT Bantaeng Sinergi Cemerlang
						{"(BASIC)"}
					</span>
				</div>
				<div className="flex justify-center items-center p-9  md:p-12 md:mx-24 lg:p-12 lg:mx-24 ">
					{open ? (
						<>
							<div className="justify-center items-center md:p-0 lg:p-0 p-5 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
								<div className="relative w-auto my-6 mx-auto max-w-3xl">
									{/*content*/}
									<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
										{/*header*/}
										<div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
											<h3 className="text-3xl text-green-400 font-semibold">
												Berhasil Dikirim
											</h3>
											<button
												className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
												onClick={() => setOpen(false)}>
												<span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
													×
												</span>
											</button>
										</div>
										{/*body*/}
										<div className="relative p-6 flex-auto">
											<p className="my-4 text-slate-500 text-lg leading-relaxed">
												Permintaan Berhasil Dikirim,kami akan segera mengirim
												data pelamar calon tenaga kerja kepada anda.
												<br />
											</p>
											<span className="text-red-400 block text-lg font-semibold mb-9">
												Terima Kasih
											</span>
											<span className="text-red-400 text-lg font-semibold mt-14">
												PT Bantaeng Sinergi Cemerlang{"(BASIC)"}
											</span>
										</div>
										{/*footer*/}
										<div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
											<button
												className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
												type="button"
												onClick={() => window.location.reload(true)}>
												Close
											</button>
											<button
												className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
												type="button"
												onClick={()=>navigate("/tabledimanis",{
													state:{
														idPosisi:data.nama_posisi,
														nama_posisi:data.nama_posisi
													}
												})}>
												Save Changes
											</button>
										</div>
									</div>
								</div>
							</div>
							<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
						</>
					) : (
						" "
					)}
					<form className={`w-full`} onSubmit={handleSubmit}>
						<p className="text-red-500 font-semibold">{msg}</p>
						{/* Perusahaan */}
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3">
								<label
									className={`block uppercase tracking-wide ${
										currentMode === "Dark" ? "text-white" : "text-black"
									} text-xs font-bold mb-2`}
									htmlFor="perusahaan">
									Nama Perusahaan Peminta<span className="text-red-600">*</span>
								</label>
								<div className="relative">
									<select
										required
										onChange={change}
										value={data.perusahaan}
										name="perusahaan"
										className="block appearance-none w-full bg-gray-200 border border-gray-200 text-black py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="perusahaan">
										<option>- - - Cari Perusahaan - - -</option>
										{perusahaan.map((item, index) => (
											<option key={index} value={item.id}>
												{item.nama_perusahaan.toUpperCase()}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>
						{/* Posisi */}
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3">
								<label
									className={`block uppercase tracking-wide ${
										currentMode === "Dark" ? "text-white" : "text-black"
									} text-xs font-bold mb-2`}
									htmlFor="nama_posisi">
									Posisi Yang Dibutuhkan<span className="text-red-600">*</span>
								</label>
								<div className="relative">
									<select
										required
										onChange={change}
										value={data.nama_posisi}
										name="nama_posisi"
										className="block uppercase appearance-none w-full bg-gray-200 border border-gray-200 text-black py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="nama_posisi">
										<option>- - - Pilih Posisi - - -</option>
										{posisi.map((item, index) => (
											<option key={index} value={item.id}>
												{item.nama_posisi}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>
						{/* Tenaga Kerja Yang Dibutuhkan */}
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3 mb-6 md:mb-0">
								<label
									className={`block uppercase tracking-wide ${
										currentMode === "Dark" ? "text-white" : "text-black"
									} text-xs font-bold mb-2`}
									htmlFor="kebutuhan_karyawan">
									Jumlah Tenaga Kerja Yang Dibutuhkan
									<span className="text-red-600">*</span>
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="kebutuhan_karyawan"
									type="number"
									name="kebutuhan_karyawan"
									required
									onChange={change}
									value={data.kebutuhan_karyawan}
									placeholder="Tenaga Kerja Yang Dibutuhkan"
								/>
							</div>
						</div>
						<div className="flex justify-center items-center text-center bg-red-600 p-4 my-7">
							<span className="font-extrabold text-white text-2xl">
								Kualifikasi
							</span>
						</div>
						{/* Kualifikasi */}
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3 mb-6 md:mb-0">
								<label
									className={`block uppercase tracking-wide ${
										currentMode === "Dark" ? "text-white" : "text-black"
									} text-xs font-bold mb-2`}
									htmlFor="kualifikasi">
									Kualifikasi Pertama <span className="text-red-600">*</span>
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="kualifikasi"
									type="text"
									name="kualifikasi"
									required
									onChange={change}
									value={data.kualifikasi}
									placeholder="Kualifikasi 1"
								/>
							</div>
						</div>
						{/* Kualifikasi Kedua */}
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3 mb-6 md:mb-0">
								<label
									className={`block uppercase tracking-wide ${
										currentMode === "Dark" ? "text-white" : "text-black"
									} text-xs font-bold mb-2`}
									htmlFor="kualifikasi2">
									Kualifikasi Kedua
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="kualifikasi2"
									type="text"
									name="kualifikasi2"
									onChange={change}
									value={data.kualifikasi2}
									placeholder="Kualifikasi 2"
								/>
							</div>
						</div>
						{/* Kualifikasi Ketiga */}
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3 mb-6 md:mb-0">
								<label
									className={`block uppercase tracking-wide ${
										currentMode === "Dark" ? "text-white" : "text-black"
									} text-xs font-bold mb-2`}
									htmlFor="kualifikasi3">
									Kualifikasi Ketiga
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="kualifikasi3"
									type="text"
									name="kualifikasi3"
									onChange={change}
									value={data.kualifikasi3}
									placeholder="Kualifikasi 3"
								/>
							</div>
						</div>
						{/* Kualifikasi Keempat */}
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3 mb-6 md:mb-0">
								<label
									className={`block uppercase tracking-wide ${
										currentMode === "Dark" ? "text-white" : "text-black"
									} text-xs font-bold mb-2`}
									htmlFor="kualifikasi4">
									Kualifikasi Keempat
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="kualifikasi4"
									type="text"
									name="kualifikasi4"
									onChange={change}
									value={data.kualifikasi4}
									placeholder="Kualifikasi 4"
								/>
							</div>
						</div>
						{/* Kualifikasi Kelima */}
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3 mb-6 md:mb-0">
								<label
									className={`block uppercase tracking-wide ${
										currentMode === "Dark" ? "text-white" : "text-black"
									} text-xs font-bold mb-2`}
									htmlFor="kualifikasi5">
									Kualifikasi Kelima
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="kualifikasi5"
									type="text"
									name="kualifikasi5"
									onChange={change}
									value={data.kualifikasi5}
									placeholder="Kualifikasi 5"
								/>
							</div>
						</div>
						<div className="flex justify-center items-center text-center bg-red-600 p-4 my-7">
							<span className="font-extrabold text-white text-2xl">
								Persyaratan Umum
							</span>
						</div>
						{/* Persyaratan Umum */}
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3 mb-6 md:mb-0">
								<label
									className={`block uppercase tracking-wide ${
										currentMode === "Dark" ? "text-white" : "text-black"
									} text-xs font-bold mb-2`}
									htmlFor="persyaratan_umum">
									Persyaratan Umum 1
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="persyaratan_umum"
									type="text"
									name="persyaratan_umum"
									onChange={change}
									value={data.persyaratan_umum}
									placeholder="Persyaratan Umum 1"
								/>
							</div>
						</div>
						{/* Persyaratan Umum2 */}
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3 mb-6 md:mb-0">
								<label
									className={`block uppercase tracking-wide ${
										currentMode === "Dark" ? "text-white" : "text-black"
									} text-xs font-bold mb-2`}
									htmlFor="persyaratan_umum2">
									Persyaratan Umum 2
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="persyaratan_umum2"
									type="text"
									name="persyaratan_umum2"
									onChange={change}
									value={data.persyaratan_umum2}
									placeholder="Persyaratan Umum 2"
								/>
							</div>
						</div>
						{/* Persyaratan Umum 3 */}
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3 mb-6 md:mb-0">
								<label
									className={`block uppercase tracking-wide ${
										currentMode === "Dark" ? "text-white" : "text-black"
									} text-xs font-bold mb-2`}
									htmlFor="persyaratan_umum3">
									Persyaratan Umum 3
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="persyaratan_umum3"
									type="text"
									name="persyaratan_umum3"
									onChange={change}
									value={data.persyaratan_umum3}
									placeholder="Persyaratan Umum 3"
								/>
							</div>
						</div>
						{/* Persyaratan Umum 4 */}
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3 mb-6 md:mb-0">
								<label
									className={`block uppercase tracking-wide ${
										currentMode === "Dark" ? "text-white" : "text-black"
									} text-xs font-bold mb-2`}
									htmlFor="persyaratan_umum4">
									Persyaratan Umum 4
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="persyaratan_umum4"
									type="text"
									name="persyaratan_umum4"
									onChange={change}
									value={data.persyaratan_umum4}
									placeholder="Persyaratan Umum "
								/>
							</div>
						</div>
						{/* Gelombang Pertama */}
						<div className="flex justify-center items-center text-center bg-red-600 p-4 my-7">
							<span className="font-extrabold text-white text-2xl">
								Waktu Test Gelombang Pertama
							</span>
						</div>
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
								<label
									className={`block uppercase tracking-wide text-white text-xs font-bold mb-2`}
									htmlFor="test_tertulis_gelombang1">
									Test Tertulis <span className="text-red-600">*</span>
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="test_tertulis_gelombang1"
									type="date"
									name="test_tertulis_gelombang1"
									min="2023-02-02"
									onChange={change}
									value={data.test_tertulis_gelombang1}
								/>
								<span className="text-white">kosongkan jika tidak ada</span>
							</div>
							<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
								<label
									className={`block uppercase tracking-wide text-white text-xs font-bold mb-2`}
									htmlFor="test_wawancara_gelombang1">
									Test Wawancara <span className="text-red-600">*</span>
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="test_wawancara_gelombang1"
									type="date"
									name="test_wawancara_gelombang1"
									required
									min="2023-02-02"
									onChange={change}
									value={data.test_wawancara_gelombang1}
								/>
							</div>
							<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
								<label
									className={`block uppercase tracking-wide text-white text-xs font-bold mb-2`}
									htmlFor="test_lapangan_gelombang1">
									Test Lapangan <span className="text-red-600">*</span>
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="test_lapangan_gelombang1"
									type="date"
									min="2023-02-02"
									name="test_lapangan_gelombang1"
									required
									onChange={change}
									value={data.test_lapangan_gelombang1}
								/>
							</div>
						</div>
						{/* Gelombang Kedua */}
						<div className="flex justify-center items-center text-center bg-red-600 p-4 my-7">
							<span className="font-extrabold text-white text-2xl">
								Waktu Test Gelombang Kedua
							</span>
						</div>
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
								<label
									className={`block uppercase tracking-wide text-white text-xs font-bold mb-2`}
									htmlFor="test_tertulis_gelombang2">
									Test Tertulis <span className="text-red-600">*</span>
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="test_tertulis_gelombang2"
									type="date"
									name="test_tertulis_gelombang2"
									min="2023-02-02"
									onChange={change}
									value={data.test_tertulis_gelombang2}
								/>
								<span className="text-white">
									kosongkan jika tidak ada gelombang 2
								</span>
							</div>
							<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
								<label
									className={`block uppercase tracking-wide text-white text-xs font-bold mb-2`}
									htmlFor="test_wawancara_gelombang2">
									Test Wawancara <span className="text-red-600">*</span>
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="test_wawancara_gelombang2"
									type="date"
									name="test_wawancara_gelombang2"
									min="2023-02-02"
									onChange={change}
									value={data.test_wawancara_gelombang2}
								/>
								<span className="text-white">
									kosongkan jika tidak ada gelombang 2
								</span>
							</div>
							<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
								<label
									className={`block uppercase tracking-wide text-white text-xs font-bold mb-2`}
									htmlFor="test_lapangan_gelombang2">
									Test Lapangan <span className="text-red-600">*</span>
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="test_lapangan_gelombang2"
									type="date"
									min="2023-02-02"
									name="test_lapangan_gelombang2"
									onChange={change}
									value={data.test_lapangan_gelombang2}
								/>
								<span className="text-white">
									kosongkan jika tidak ada gelombang 2
								</span>
							</div>
						</div>
						{/* Gelombang Ketiga */}
						<div className="flex justify-center items-center text-center bg-red-600 p-4 my-7">
							<span className="font-extrabold text-white text-2xl">
								Waktu Test Gelombang Ketiga
							</span>
						</div>
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
								<label
									className={`block uppercase tracking-wide text-white text-xs font-bold mb-2`}
									htmlFor="test_tertulis_gelombang3">
									Test Tertulis <span className="text-red-600">*</span>
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="test_tertulis_gelombang3"
									type="date"
									name="test_tertulis_gelombang3"
									min="2023-02-02"
									onChange={change}
									value={data.test_tertulis_gelombang3}
								/>
								<span className="text-white">
									kosongkan jika tidak ada gelombang 3
								</span>
							</div>
							<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
								<label
									className={`block uppercase tracking-wide text-white text-xs font-bold mb-2`}
									htmlFor="test_wawancara_gelombang3">
									Test Wawancara <span className="text-red-600">*</span>
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="test_wawancara_gelombang3"
									type="date"
									name="test_wawancara_gelombang3"
									min="2023-02-02"
									onChange={change}
									value={data.test_wawancara_gelombang3}
								/>
								<span className="text-white">
									kosongkan jika tidak ada gelombang 3
								</span>
							</div>
							<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
								<label
									className={`block uppercase tracking-wide text-white text-xs font-bold mb-2`}
									htmlFor="test_lapangan_gelombang3">
									Test Lapangan <span className="text-red-600">*</span>
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
									id="test_lapangan_gelombang3"
									type="date"
									min="2023-02-02"
									name="test_lapangan_gelombang3"
									onChange={change}
									value={data.test_lapangan_gelombang3}
								/>
								<span className="text-white">
									kosongkan jika tidak ada gelombang 3
								</span>
							</div>
						</div>
						{/* Pengumuman Hasil Test */}
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3">
								<label
									className={`block uppercase tracking-wide text-white text-xs font-bold mb-2`}
									htmlFor="pengumuman_seleksi">
									Pengumuman Hasil Test
								</label>
								<input
									className="appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="pengumuman_seleksi"
									type="text"
									onChange={change}
									value={data.pengumuman_seleksi}
									name="pengumuman_seleksi"
									placeholder="kosongkan jika tidak ada"
								/>
							</div>
						</div>
						{/* Button */}
						<button
							data-modal-toggle="small-modal"
							type="submit"
							className="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 text-lg rounded-lg p-4 hover: text-center font-bold dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
							{loading ? (
								<div role="status">
									<svg
										aria-hidden="true"
										className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
										viewBox="0 0 100 101"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
											fill="currentColor"
										/>
										<path
											d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
											fill="currentFill"
										/>
									</svg>
									<span className="sr-only">Loading...</span>
								</div>
							) : (
								"Input Data"
							)}
						</button>
						<p className="text-red-500 font-semibold">{msg}</p>
					</form>
				</div>
			</div>
		</>
	);
};

export default Stacked;
