import React, { useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useLocation } from "react-router-dom";

const Pdf = () => {
	const location = useLocation();
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const goToPrevPage = () =>
		setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
	const goToNextPage = () =>
		setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
	console.log(numPages);
	return (
		<>
			<div className="flex min-h-screen items-center justify-center bg-gray-400">
				<Document
					file={`https://api.ctk-kiba.com/api/pelamar/buffer/${location.state.nama_file}`}
					onLoadSuccess={onDocumentLoadSuccess}>
					<Page pageNumber={pageNumber} />
				</Document>
			</div>
			<div className="mt-5 flex w-full items-center justify-center gap-20">
				<button
					className="rounded-md bg-blue-400 px-4 py-2"
					onClick={goToPrevPage}>
					<AiOutlineArrowLeft />
				</button>
				<button
					className="rounded-md bg-blue-400 px-4 py-2"
					onClick={goToNextPage}>
					<AiOutlineArrowRight />
				</button>
			</div>
			Halaman {pageNumber} dari {numPages}
		</>
	);
};

export default Pdf;
