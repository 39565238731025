import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../context/ContextProvider";
import { ToastContainer } from "react-toastify";
import { notifyGagal, notifySukses } from "../notif/Notif";

const FormInputPelamar = () => {
	const [loading, setLoading] = useState(false);
	const { currentColor, roles, token, currentMode } = useStateContext();
	const [msg, setMsg] = useState("");
	const [allProvinsi, setAllProvinsi] = useState([]);
	const [allKabupaten, setAllKabupaten] = useState([]);
	const [allKecamatan, setAllKecamatan] = useState([]);
	const [allDesa, setAllDesa] = useState([]);
	const [allPosisi, setAllPosisi] = useState([]);
	const [codeResponse, setCodeResponse] = useState(null);
	let [data, setData] = useState({
		nik: "",
		nama_lengkap: "",
		dusun: "",
		posisi: "",
		jenis_kelamin: "",
		tempat_lahir: "",
		no_telpon: "",
		tanggal_lahir: "",
		pendidikan_terakhir: "",
		pengalaman_kerja: "",
		pengalaman_kerja2: "",
		pengalaman_kerja3: "",
	});
	const [selectedFile, setSelectedFile] = useState("");
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [provinsi, setProvinsi] = useState();
	const [kabupaten, setKabupaten] = useState();
	const [kecamatan, setKecamatan] = useState();
	const [desa, setDesa] = useState();
	let [namaProvinsi, setNamaProvinsi] = useState("");
	let [namaKabupaten, setNamaKabupaten] = useState("");
	let [namaKecamatan, setNamaKecamatan] = useState("");
	let [namaDesa, setNamaDesa] = useState("");
	const [nik, setNik] = useState("");

	const handleNumChange = (event) => {
		setNik(event.target.value.slice(0, 16));
	};

	const handleChangeProvinsi = (event) => {
		const selectedId = event.target.value;
		setProvinsi(selectedId);
		const selectedProvinsi = allProvinsi.find((desa) => desa.id === selectedId);
		setNamaProvinsi(selectedProvinsi ? selectedProvinsi.name : "");
	};

	const handleChangeKabupaten = (event) => {
		const selectedId = event.target.value;
		setKabupaten(selectedId);
		const selectedProvinsi = allKabupaten.find(
			(desa) => desa.id === selectedId
		);
		setNamaKabupaten(selectedProvinsi ? selectedProvinsi.name : "");
	};

	const handleChangeKecamatan = (event) => {
		const selectedId = event.target.value;
		setKecamatan(selectedId);
		const selectedProvinsi = allKecamatan.find(
			(desa) => desa.id === selectedId
		);
		setNamaKecamatan(selectedProvinsi ? selectedProvinsi.name : "");
	};

	const handleChangeDesa = (event) => {
		const selectedId = event.target.value;
		setDesa(selectedId);
	};

	const getPosisi = async () => {
		const response = await axios.get(
			"https://api.ctk-kiba.com/api/posisi/public",
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		setAllPosisi(response.data);
	};

	const getProvinsi = async () => {
		const response = await axios.get(
			"https://asdarsaid377.github.io/api-wilayah-indonesia/api/provinces.json"
		);
		setAllProvinsi(response.data);
	};

	const getKabupaten = async () => {
		const response = await axios.get(
			`https://asdarsaid377.github.io/api-wilayah-indonesia/api/regencies/${provinsi}.json`
		);
		setAllKabupaten(response.data);
	};

	const getKecamatan = async () => {
		const response = await axios.get(
			`https://asdarsaid377.github.io/api-wilayah-indonesia/api/districts/${kabupaten}.json`
		);
		setAllKecamatan(response.data);
	};

	const getDesa = async () => {
		const response = await axios.get(
			`https://asdarsaid377.github.io/api-wilayah-indonesia/api/villages/${kecamatan}.json`
		);
		setAllDesa(response.data);
	};

	const navigate = useNavigate();
	const handleSubmit = async (e) => {
		setLoading(true);
		e.preventDefault();
		try {
			const formData = new FormData();
			formData.append("nik", nik);
			formData.append("nama_lengkap", data.nama_lengkap);
			formData.append("tempat_lahir", data.tempat_lahir);
			formData.append("tanggal_lahir", data.tanggal_lahir);
			formData.append("provinsi", namaProvinsi);
			formData.append("kabupaten", namaKabupaten);
			formData.append("kecamatan", namaKecamatan);
			formData.append("desa", namaDesa);
			formData.append("dusun", data.dusun);
			formData.append("jenis_kelamin", data.jenis_kelamin);
			formData.append("no_telpon", data.no_telpon);
			formData.append("posisi", data.posisi);
			formData.append("pendidikan_terakhir", data.pendidikan_terakhir);
			formData.append("pengalaman_kerja", data.pengalaman_kerja);
			formData.append("pengalaman_kerja2", data.pengalaman_kerja2);
			formData.append("pengalaman_kerja3", data.pengalaman_kerja3);
			if (
				namaDesa.toLowerCase() == "papanloe" ||
				namaDesa.toLowerCase() == "borongloe" ||
				namaDesa.toLowerCase() == "baruga"
			) {
				formData.append("keterangan", "Desa Terdampak");
			} else {
				formData.append("keterangan", "umum");
			}
			formData.append("dokumen", selectedFile);
			const add = await axios.post(
				"https://api.ctk-kiba.com/api/pelamar",
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (add.data.status === 409) {
				notifyGagal();
				setMsg(add.data.response);
			} else {
				notifySukses();
				setTimeout(() => {
					navigate("/semuadata");
				}, 2000);
			}
			setLoading(false);
		} catch (error) {
			console.log(error);
			notifyGagal();
			setLoading(false);
		}
	};

	useEffect(() => {
		getProvinsi();
		getKota();
		getKecamatan();
		getDesa();
		getPosisi();
		if (provinsi) {
			getKabupaten();
			if (kabupaten) {
				getKecamatan();
			}
			if (kecamatan) {
				getDesa();
			}
		}
	}, [provinsi, kabupaten, kecamatan, desa]);

	const change = (e) => {
		const fieldName = e.target.name;
		setData((exist) => ({
			...exist,
			[fieldName]: e.target.value,
		}));
	};

	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
	};

	return (
		<>
			<ToastContainer
				position="top-right"
				autoClose={2000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<div>
				<form className={`w-full`} onSubmit={handleSubmit}>
					<p className="text-red-500 font-semibold">{msg}</p>
					{/* NIK DAN NAMA LENGKAP */}
					<div className="flex flex-wrap -mx-3 mb-6">
						<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
							<label
								className={`block uppercase tracking-wide ${
									currentMode === "Dark" ? "text-white" : "text-gray-700"
								} text-xs font-bold mb-2`}
								htmlFor="nik">
								NIK <span className="text-red-600">*</span>
							</label>
							<input
								className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
								id="nik"
								type="number"
								name="nik"
								required
								onChange={handleNumChange}
								value={nik}
								placeholder="Nomor induk kependudukan"
							/>
						</div>
						<div className="w-full md:w-1/2 px-3">
							<label
								className={`block uppercase tracking-wide ${
									currentMode === "Dark" ? "text-white" : "text-gray-700"
								} text-xs font-bold mb-2`}
								htmlFor="nama_lengkap">
								Nama Lengkap <span className="text-red-600">*</span>
							</label>
							<input
								className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="nama_lengkap"
								type="text"
								name="nama_lengkap"
								onChange={change}
								value={data.nama_lengkap}
								placeholder="Nama Legkap"
								required
							/>
						</div>
					</div>
					{/* Tempat Tanggal Lahir */}
					<div className="flex flex-wrap -mx-3 mb-6">
						<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
							<label
								className={`block uppercase tracking-wide ${
									currentMode === "Dark" ? "text-white" : "text-gray-700"
								} text-xs font-bold mb-2`}
								htmlFor="tempat_lahir">
								Tempat Lahir <span className="text-red-600">*</span>
							</label>
							<input
								className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
								id="tempat_lahir"
								type="text"
								name="tempat_lahir"
								required
								onChange={change}
								value={data.tempat_lahir}
								placeholder="Tempat Lahir"
							/>
						</div>
						<div className="w-full md:w-1/2 px-3">
							<label
								className={`block uppercase tracking-wide ${
									currentMode === "Dark" ? "text-white" : "text-gray-700"
								} text-xs font-bold mb-2`}
								htmlFor="tanggal_lahir">
								Tanggal Lahir <span className="text-red-600">*</span>
							</label>
							<input
								className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="tanggal_lahir"
								type="date"
								min="1973-01-01"
								max="2009-01-01"
								name="tanggal_lahir"
								onChange={change}
								value={data.tanggal_lahir}
								placeholder="Nama Legkap"
								required
							/>
						</div>
					</div>
					{/* Jenis Kelamin */}
					<div className="flex flex-wrap -mx-3 mb-6">
						<div className="w-full px-3">
							<label
								className={`block uppercase tracking-wide ${
									currentMode === "Dark" ? "text-white" : "text-gray-700"
								} text-xs font-bold mb-2`}
								htmlFor="jenis_kelamin">
								Jenis Kelamin <span className="text-red-600">*</span>
							</label>
							<div className="relative">
								<select
									required
									onChange={change}
									value={data.jenis_kelamin}
									name="jenis_kelamin"
									className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="jenis_kelamin">
									<option>- - - Jenis Kelamin - - -</option>
									<option>Laki-Laki</option>
									<option>Perempuan</option>
								</select>
							</div>
						</div>
					</div>
					{/* No Telpon */}
					<div className="flex flex-wrap -mx-3 mb-6">
						<div className="w-full px-3">
							<label
								className={`block uppercase tracking-wide ${
									currentMode === "Dark" ? "text-white" : "text-gray-700"
								} text-xs font-bold mb-2`}
								htmlFor="no_telpon">
								No Telpon
							</label>
							<input
								className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="no_telpon"
								type="number"
								onChange={change}
								value={data.no_telpon}
								name="no_telpon"
								placeholder="No Telpon"
							/>
						</div>
					</div>
					{/* Area */}
					<div className="flex flex-wrap -mx-3 mb-6">
						<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
							<label
								className={`block uppercase tracking-wide ${
									currentMode === "Dark" ? "text-white" : "text-gray-700"
								} text-xs font-bold mb-2`}
								htmlFor="provinsi">
								Provinsi <span className="text-red-600">*</span>
							</label>
							<div className="relative">
								<select
									onChange={handleChangeProvinsi}
									value={provinsi}
									name="provinsi"
									className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="provinsi">
									<option>- - - Pilih Provinsi - - -</option>
									{allProvinsi.map((options) => (
										<option key={options.id} value={options.id}>
											{options.name}
										</option>
									))}
								</select>
								<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
									<svg
										className="fill-current h-4 w-4"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"></svg>
								</div>
							</div>
						</div>
						<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
							<label
								className={`block uppercase tracking-wide ${
									currentMode === "Dark" ? "text-white" : "text-gray-700"
								} text-xs font-bold mb-2`}
								htmlFor="kabupaten">
								Kabupaten <span className="text-red-600">*</span>
							</label>
							<div className="relative">
								<select
									onChange={handleChangeKabupaten}
									value={kabupaten}
									name="kecamatan"
									className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="kabupaten">
									<option>- - - Pilih Kabupaten - - -</option>
									{allKabupaten.map((option) => (
										<option key={option.id} value={option.id}>
											{option.name}
										</option>
									))}
								</select>
								<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
									<svg
										className="fill-current h-4 w-4"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"></svg>
								</div>
							</div>
						</div>
						<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
							<label
								className={`block uppercase tracking-wide ${
									currentMode === "Dark" ? "text-white" : "text-gray-700"
								} text-xs font-bold mb-2`}
								htmlFor="kecamatan">
								Kecamatan <span className="text-red-600">*</span>
							</label>
							<div className="relative">
								<select
									onChange={handleChangeKecamatan}
									value={kecamatan}
									name="kecamatan"
									className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="kecamatan">
									<option>- - - Pilih Kecamatan - - -</option>
									{allKecamatan.map((option) => (
										<option key={option.id} value={option.id}>
											{option.name}
										</option>
									))}
								</select>
								<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
									<svg
										className="fill-current h-4 w-4"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"></svg>
								</div>
							</div>
						</div>
						<div className="w-full md:mt-5 md:w-1/3 px-3 mb-6 md:mb-0">
							<label
								className={`block uppercase tracking-wide ${
									currentMode === "Dark" ? "text-white" : "text-gray-700"
								} text-xs font-bold mb-2`}
								htmlFor="desa">
								Desa/Kelurahan <span className="text-red-600">*</span>
							</label>
							<div className="relative">
								<select
									className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="desa"
									onChange={handleChangeDesa}
									value={desa}>
									<option>- - - Pilih Desa - - -</option>
									{allDesa.map((option) => (
										<option key={option.id} value={option.id}>
											{option.name}
										</option>
									))}
								</select>
								<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
									<svg
										className="fill-current h-4 w-4"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"></svg>
								</div>
							</div>
						</div>
						<div className="w-full md:mt-5 md:w-1/3 px-3  md:mb-0">
							<label
								className={`block uppercase tracking-wide ${
									currentMode === "Dark" ? "text-white" : "text-gray-700"
								} text-xs font-bold mb-2`}
								htmlFor="dusun">
								Dusun <span className="text-red-600">*</span>
							</label>
							<input
								className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="dusun"
								type="text"
								onChange={change}
								value={data.dusun}
								name="dusun"
								placeholder="nama dusun"
							/>
							<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
								<svg
									className="fill-current h-4 w-4"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"></svg>
							</div>
						</div>
					</div>
					{/* Posisi */}
					<div className="flex flex-wrap -mx-3 mb-6">
						<div className="w-full px-3">
							<label
								className={`block uppercase tracking-wide ${
									currentMode === "Dark" ? "text-white" : "text-gray-700"
								} text-xs font-bold mb-2`}
								htmlFor="posisi">
								Posisi <span className="text-red-600">*</span>
							</label>
							<div className="relative">
								<select
									required
									onChange={change}
									value={data.posisi}
									name="posisi"
									className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="posisi">
									<option>- - - Pilih Posisi - - -</option>
									{allPosisi.map((option) => (
										<option key={option.id} value={option.id}>
											{option.nama_posisi}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
					{/* Pendidikan Terakhir */}
					<div className="flex flex-wrap -mx-3 mb-6">
						<div className="w-full px-3">
							<label
								className={`block uppercase tracking-wide ${
									currentMode === "Dark" ? "text-white" : "text-gray-700"
								} text-xs font-bold mb-2`}
								htmlFor="pendidikan_terakhir">
								Pendidikan Terakhir <span className="text-red-600">*</span>
							</label>
							<div className="relative">
								<select
									required
									onChange={change}
									value={data.pendidikan_terakhir}
									name="pendidikan_terakhir"
									className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="pendidikan_terakhir">
									<option>- - - Pendidikan Terakhir - - -</option>
									<option>SD</option>
									<option>SMP</option>
									<option>SMA</option>
									<option>D2</option>
									<option>D3</option>
									<option>S1/D4</option>
									<option>S2</option>
								</select>
							</div>
						</div>
					</div>
					{/* Pengalaman Kerja */}
					<div className="flex flex-wrap -mx-3 mb-6">
						<div className="w-full px-3">
							<label
								className={`block uppercase tracking-wide ${
									currentMode === "Dark" ? "text-white" : "text-gray-700"
								} text-xs font-bold mb-2`}
								htmlFor="pengalaman_kerja">
								Pengalaman Kerja
							</label>
							<input
								className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="pengalaman_kerja"
								type="text"
								onChange={change}
								value={data.pengalaman_kerja}
								name="pengalaman_kerja"
								placeholder="kosongkan jika tidak ada"
							/>
						</div>
					</div>
					{/* Pengalaman Kerja 2 */}
					<div className="flex flex-wrap -mx-3 mb-6">
						<div className="w-full px-3">
							<label
								className={`block uppercase tracking-wide ${
									currentMode === "Dark" ? "text-white" : "text-gray-700"
								} text-xs font-bold mb-2`}
								htmlFor="pengalaman_kerja2">
								Pengalaman Kerja 2
							</label>
							<input
								className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="pengalaman_kerja2"
								type="text"
								onChange={change}
								value={data.pengalaman_kerja2}
								name="pengalaman_kerja2"
								placeholder="kosongkan jika tidak ada"
							/>
						</div>
					</div>
					{/* Pengalaman Kerja 3 */}
					<div className="flex flex-wrap -mx-3 mb-6">
						<div className="w-full px-3">
							<label
								className={`block uppercase tracking-wide ${
									currentMode === "Dark" ? "text-white" : "text-gray-700"
								} text-xs font-bold mb-2`}
								htmlFor="pengalaman_kerja3">
								Pengalaman Kerja 3
							</label>
							<input
								className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="pengalaman_kerja3"
								type="text"
								onChange={change}
								value={data.pengalaman_kerja3}
								name="pengalaman_kerja3"
								placeholder="kosongkan jika tidak ada"
							/>
						</div>
					</div>
					{/* FILE */}
					<div className="flex flex-wrap -mx-3 mb-6">
						<div className="w-full px-3">
							<label
								className={`block uppercase tracking-wide ${
									currentMode === "Dark" ? "text-white" : "text-gray-700"
								} text-xs font-bold mb-2`}
								htmlFor="file">
								Berkas Pelamar
							</label>
							<input
								className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="file"
								type="file"
								name="file"
								required
								onChange={changeHandler}
								placeholder="1234567"
							/>
						</div>
					</div>
					{/* Button */}
					<button
						data-modal-toggle="small-modal"
						type="submit"
						style={{ backgroundColor: currentColor }}
						className="text-white bg-orange-300 hover:bg-orange-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
						{loading ? (
							<div role="status">
								<svg
									aria-hidden="true"
									className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
									viewBox="0 0 100 101"
									fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
										fill="currentColor"
									/>
									<path
										d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
										fill="currentFill"
									/>
								</svg>
								<span className="sr-only">Loading...</span>
							</div>
						) : (
							"Input Data"
						)}
					</button>
					<p className="text-red-500 font-semibold">{msg}</p>
				</form>
				<p className="md:hidden lg:hidden text-red-500 font-semibold mt-3">
					{msg}
				</p>
			</div>
		</>
	);
};

export default FormInputPelamar;
