import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useStateContext } from "../../context/ContextProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard3 = () => {
	const [posisi, setPosisi] = useState([]);
	const [data, setData] = useState([]);
	const navigate = useNavigate();
	const { token, roles } = useStateContext();

	const getData = async () => {
		const response = await axios.get("https://api.ctk-kiba.com/api/posisi");
		setData(response.data);
		setPosisi(response.data.length);
	};

	let pos = [];
	let pelamar = [];
	const nama_posisi = [];
	data.map((item) => {
		pos.push(item.nama_posisi);
		pelamar.push(item.pelamarCount);
		nama_posisi.push(item.nama_posisi);
	});

	useEffect(() => {
		if (!token) {
			navigate("/");
		}
		if (roles !== "admin") {
			navigate("/");
		}
		getData();
	}, []);

	const bg = [
		"#004E98",
		"#BFCC94",
		"#7A3B69",
		"#197278",
		"#EE4266",
		"#07A0C3",
		"#F0C808",
		"#086788",
		"#E28413",
		"#DE3C4B",
		"#004E98",
		"#BFCC94",
		"#7A3B69",
		"#197278",
		"#EE4266",
		"#07A0C3",
		"#F0C808",
		"#086788",
		"#E28413",
		"#DE3C4B",
		"#004E98",
		"#BFCC94",
		"#7A3B69",
		"#197278",
		"#EE4266",
		"#07A0C3",
		"#F0C808",
		"#086788",
		"#E28413",
		"#DE3C4B",
	];

	const datas = {
		labels: pos,
		datasets: [
			{
				label: "Pelamar",
				data: pelamar,
				backgroundColor: bg,
				borderColor: ["#ffffff"],
				borderWidth: 2,
			},
		],
	};
	const { currentColor, currentMode, activeMenu, setActiveMenu, screenSize } =
		useStateContext();
	return (
		<>
			<div
				className={`flex justify-center rounded-xl ${
					currentMode === "Dark" ? "bg-secondary-dark-bg" : "bg-gray-50"
				}  drop-shadow-xl items-center p-7 w-full`}>
				<Pie data={datas} />
			</div>
		</>
	);
};

export default Dashboard3;
