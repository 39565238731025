import React, { useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useStateContext } from "../context/ContextProvider";
import { Button } from ".";
import produk from "../data/product2.jpg";

const Cart = () => {
	const { currentColor, handleClick } = useStateContext();
	const [count, setCount] = useState(0);
	const [price, setPrice] = useState(200000);
	const [total, setTotal] = useState(0);

	useEffect(() => {
		setTotal(price * count);
	}, [count, price]);

	return (
		<>
			<div className="bg-half-transparent w-full fixed nav-item top-0 right-0 ">
				<div className="float-right ml-9 min-h-screen duration-1000 ease-in-out dark:text-gray-200 transition-all dark:bg-[#484B52] bg-white md:w-400 p-8">
					<div className="flex justify-between items-center">
						<p className="font-semibold text-lg">Shopping Cart</p>
						<button
							type="button"
							onClick={() => handleClick("")}
							style={{ color: currentColor }}
							className="text-2xl p-3 hover:drop-shadow-xl rounded-[50%] hover:bg-gray-200">
							<MdOutlineCancel />
						</button>
					</div>
					<div>
						<div className="flex items-center   leading-8 gap-5 border-b-1 border-color dark:border-gray-600 p-4">
							<img className="rounded-lg h-80 w-24" src={produk} alt="" />
							<div>
								<p className="font-semibold ">Nama Produk</p>
								<p className="text-gray-600 dark:text-gray-400 text-sm font-semibold">
									Gadget
								</p>
								<div className="flex gap-4 mt-2 items-center">
									<p
										className="font-semibold flex items-center text-lg"
										style={{ color: currentColor }}>
										<span>Rp </span> {price}
									</p>
									<div className="flex items-center border-1 border-r-0 border-color rounded">
										<div
											className="p-2 hover:drop-shadow-xl border-r-1 dark:border-gray-600 cursor-pointer border-color text-red-600"
											onClick={() => setCount(count === 0 ? 0 : count - 1)}>
											<AiOutlineMinus />
										</div>
										<div className="p-2 border-r-1 border-color dark:border-gray-600 text-green-600">
											{count}
										</div>
										<div
											className="p-2 border-r-1 border-color cursor-pointer dark:border-gray-600 text-green-600"
											onClick={() => setCount(count + 1)}>
											<AiOutlinePlus />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="mt-3 mb-3">
						<div className="flex justify-between items-center">
							<p className="text-gray-500 dark:text-gray-200">Sub Total</p>
							<p className="font-semibold">{total}</p>
						</div>
						<div className="flex justify-between items-center mt-3">
							<p className="text-gray-500 dark:text-gray-200">Total</p>
							<p className="font-semibold">{total}</p>
						</div>
					</div>
					<div className="mt-5">
						<Button
							color="white"
							bgColor={currentColor}
							text="Place Order"
							borderRadius="10px"
							width="full"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Cart;
