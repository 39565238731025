import React from "react";

const Button = ({ bgColor, color, text, size, borderRadius }) => {
	return (
		<>
			<button
				type="button"
				style={{ backgroundColor: bgColor, color, borderRadius }}
				className={`text-${size} hover:bg-opacity-60 p-3 hover:drop-shadow-xl`}>
				{text}
			</button>
		</>
	);
};

export default Button;
