import React, { useEffect } from 'react';
import { useStateContext } from '../context/ContextProvider';
import { useNavigate } from 'react-router-dom';
import Children from '../layout/Children';
import { Footer, Header } from '../components';
import TablePelamarLulus from '../components/table/TablePelamarLulus';

const PelamarLulus = () => {
  const { roles, token } = useStateContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      navigate('/');
    }
    if (roles !== 'admin') {
      navigate('/');
    }
  }, []);

  return (
    <>
      <Children>
        <div className=" md:m-10 min-h-screen p-5 bg-white rounded-xl dark:bg-secondary-dark-bg">
          <Header title="Pelamar Yang Telah Lulus" category="Data" />
          <TablePelamarLulus />
          <Footer />
        </div>
      </Children>
    </>
  );
};

export default PelamarLulus;
