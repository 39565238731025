import React from "react";
import { Footer, Header } from "../components";
import { useStateContext } from "../context/ContextProvider";
import Children from "../layout/Children";
import TableData from "../components/table/TablePemilih";

const Calendar = () => {
	const { currentMode } = useStateContext();
	return (
		<Children>
			<div className="md:m-10 min-h-screen p-5 bg-white rounded-xl dark:bg-secondary-dark-bg">
				<Header category="Halaman" title="Tanggal Kegiatan" />
				<TableData />
				<Footer />
			</div>
		</Children>
	);
};

export default Calendar;
