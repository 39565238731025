import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { GiMiningHelmet } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';

const CardLulusKerja = () => {
  const [total, setTotal] = useState();
  const token = Cookies.get('acces_token');
  const getData = async () => {
    const response = await axios.get(
      'https://api.ctk-kiba.com/api/pelamar/countlulus',
    );
    setTotal(response.data);
  };
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  const navigate = useNavigate();
  const moveClick = () => {
    navigate('/pelamarlulus');
  };
  useEffect(() => {
    if (token !== '') {
      getData();
    } else {
      navigate('/');
    }
  }, []);

  return (
    <>
      <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
        <div className="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-blue-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            aria-hidden="true"
            className="w-6 h-6 text-white"
          >
            <GiMiningHelmet />
          </svg>
        </div>
        <div className="p-4 text-right">
          <p className="block antialiased font-sans leading-normal font-normal text-blue-600">
            Total Pelamar Yang Telah Lulus Kerja
          </p>
          <h4 className="block antialiased tracking-normal font-sans text-2xl font-bold leading-snug text-blue-gray-900">
            {total}
          </h4>
        </div>
        <div
          onClick={moveClick}
          className="border-t hover:cursor-pointer border-blue-gray-50 p-4"
        >
          <div className="flex justify-between items-center ">
            <p className="block antialiased font-sans text-base leading-relaxed font-bold text-blue-gray-600">
              Lihat Data
            </p>
            <AiOutlineArrowRight />
          </div>
        </div>
      </div>
    </>
  );
};

export default CardLulusKerja;
