import React from "react";
import { useStateContext } from "../context/ContextProvider";

const Footer = () => {
	const { currentColor, currentMode, setCurrentColor } = useStateContext();
	return (
		<div className="mt-12">
			<div className="flex justify-start items-center">
				<span
					className={`${
						currentMode === "Dark" ? "text-white" : "text-gray-600"
					} `}>
					PT.Bantaeng Sinergi Cemerlang Development By Asdar Said
				</span>
			</div>
		</div>
	);
};

export default Footer;
