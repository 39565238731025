// import React, { useEffect, useState } from "react";
// import { Footer, Header } from "../components";
// import Children from "../layout/Children";
// import TableData from "../components/table/TablePemilih";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { HiOutlineUserGroup } from "react-icons/hi";
// import { AiOutlineArrowRight } from "react-icons/ai";
// import { useStateContext } from "../context/ContextProvider";

// const Editor = () => {
// 	const [total, setTotal] = useState();
// 	const [data, setData] = useState([]);
// 	let [state, setState] = useState("");
// 	let [totalPelamar, setTotalPelamar] = useState([]);
// 	const { token, roles, currentColor } = useStateContext();
// 	const getData = async () => {
// 		const response = await axios.get(
// 			"https://api.ctk-kiba.com/api/pelamar/status",
// 			{
// 				headers: {
// 					Authorization: `Bearer ${token}`,
// 				},
// 			}
// 		);
// 		setData(response.data);
// 	};

// 	const navigate = useNavigate();
// 	useEffect(() => {
// 		if (!token) {
// 			navigate("/");
// 		}
// 		if (roles !== "admin") {
// 			navigate("/");
// 		}
// 		getData();
// 	}, []);

// 	return (
// 		<Children>
// 			<div className=" md:m-10 min-h-screen p-5 bg-white rounded-xl dark:bg-secondary-dark-bg">
// 				<Header category="Data" title="Status Test CTK" />
// 				<div className="mt-16">
// 					<div className="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4">
// 						{data.map((item, index) => (
// 							<div
// 								key={index}
// 								onClick={() =>
// 									setState(
// 										(state = item.status),
// 										navigate("/tablestatus", {
// 											state: {
// 												nama_posisi: state,
// 												idPosisi: item.id,
// 											},
// 										})
// 									)
// 								}
// 								className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
// 								<div className="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-blue-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
// 									<HiOutlineUserGroup />
// 								</div>
// 								<div className="p-4 text-right">
// 									<p className="block antialiased font-sans leading-normal font-semibold text-blue-600">
// 										<span className="text-black font-bold">
// 											{item.status.toUpperCase()}
// 										</span>
// 									</p>
// 									<span
// 										style={{ color: currentColor }}
// 										className={`text-blue-500 tracking-normal font-sans text-lg font-bold leading-snug`}>
// 										{item.pelamarCount} Pelamar
// 									</span>
// 								</div>
// 								<div
// 									onClick={() => setState((state = item.nama_posisi))}
// 									className="border-t hover:cursor-pointer border-blue-gray-50 p-4">
// 									<div className="flex justify-between items-center ">
// 										<p className="block antialiased font-sans text-base leading-relaxed font-bold text-blue-gray-600">
// 											Lihat Data
// 										</p>
// 										<AiOutlineArrowRight />
// 									</div>
// 								</div>
// 							</div>
// 						))}
// 					</div>
// 				</div>
// 				<Footer />
// 			</div>
// 		</Children>
// 	);
// };

// export default Editor;
