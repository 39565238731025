import React, { useEffect, useState } from "react";
import axios from "axios";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BsFileEarmarkPdf, BsPencilSquare } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { BsPlusSquareFill } from "react-icons/bs";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useStateContext } from "../../context/ContextProvider";

const TableData = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [posisi, setPosisi] = useState();
	const { currentColor } = useStateContext();

	const getData = async () => {
		setLoading(true);
		const response = await axios.get("https://api.ctk-kiba.com/api/posisi");
		setPosisi(response.data);
		setData(response.data.pelamar);
		setLoading(false);
	};

	const deleteUser = async (id) => {
		try {
			await axios.delete(`https://api.ctk-kiba.com/api/pelamar/${id}`);
			getData();
		} catch (error) {
			console.log(error);
		}
	};

	React.useEffect(() => {
		getData();
	}, []);

	return (
		<>
			<div className="sm:flex md:mb-4 lg:mb-4 sm:justify-between sm:items-center ">
				<div className="flex justify-between items-center">
					<div className="items-center flex justify-center ">
						<div className="input-group relative flex gap-3 items-stretch w-full ">
							<input
								type="search"
								// value={search}
								// onChange={(e) => setSearch(e.target.value)}
								className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								placeholder="Search"
								aria-label="Search"
								aria-describedby="button-addon2"
							/>
							<button
								style={{ backgroundColor: currentColor }}
								className="btn px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center"
								type="submit"
								id="button-addon2">
								<svg
									aria-hidden="true"
									focusable="false"
									data-prefix="fas"
									data-icon="search"
									className="w-4"
									role="img"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 512 512">
									<path
										fill="currentColor"
										d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
									/>
								</svg>
							</button>
						</div>
					</div>
				</div>
				<div className="md:flex mt-3 mb-3 md:mt-0 md:mb-0 text-center justify-center items-center">
					<NavLink end to="/dashboard/inputpemilih">
						<div
							style={{ backgroundColor: currentColor }}
							className="flex gap-3 items-center justify-center w-[50%] md:w-full lg:w-full text-white bg-blue-600 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
							<BsPlusSquareFill />
							Input Data
						</div>
					</NavLink>
				</div>
			</div>
			{loading ? (
				<div
					role="status"
					className="w-full h-[60%] flex justify-center items-center">
					<svg
						aria-hidden="true"
						className="mr-2 w-52 h-52 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
						viewBox="0 0 100 101"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
							fill="currentColor"
						/>
						<path
							d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
							fill="currentFill"
						/>
					</svg>
					<span className="sr-only">Loading...</span>
				</div>
			) : (
				<div className="flex flex-col">
					<div className=" overflow-auto sm:mx-0.5 lg:mx-0">
						<div className="py-2 inline-block min-w-full sm:px-6 lg:px-0 md:px-0">
							<div className="overflow-hidden">
								<table className="min-w-full">
									<thead className="bg-gray-200 border-b">
										<tr>
											<th
												scope="col"
												className="text-sm font-medium text-gray-900 px-6 py-4 text-left whitespace-nowrap">
												ID
											</th>
											<th
												scope="col"
												className="text-sm font-medium text-gray-900 px-6 py-4 text-left whitespace-nowrap">
												Nama Lengkap
											</th>
											<th
												scope="col"
												className="text-sm font-medium text-center text-gray-900 px-6 py-4  whitespace-nowrap">
												NIK
											</th>
											<th
												scope="col"
												className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
												Posisi
											</th>
											<th
												scope="col"
												className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
												Tempat Lahir
											</th>
											<th
												scope="col"
												className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
												Tanggal Lahir
											</th>
											<th
												scope="col"
												className="text-sm font-medium text-gray-900 px-8 py-4 text-center  whitespace-nowrap">
												Provinsi
											</th>
											<th
												scope="col"
												className="text-sm font-medium text-gray-900 px-8 py-4 text-center whitespace-nowrap">
												Kabupaten
											</th>
											<th
												scope="col"
												className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
												Kecamatan
											</th>
											<th
												scope="col"
												className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
												Desa/Kelurahan
											</th>
											<th
												scope="col"
												className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
												Dusun
											</th>
											<th
												scope="col"
												className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
												Pendidikan Terakhir
											</th>
											<th
												scope="col"
												className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
												Pengalaman Kerja
											</th>
											<th
												scope="col"
												className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
												Dokumen
											</th>
											<th
												scope="col"
												className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
												Actions
											</th>
										</tr>
									</thead>
									<tbody>
										{data.map((item, index) => (
											<tr
												key={item.id}
												className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
												<td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
													{index + 1}
												</td>

												<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
													{item.nama_lengkap}
												</td>
												<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
													{item.nik}
												</td>
												<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
													{posisi.nama_posisi.toUpperCase()}
												</td>
												<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
													{item.tempat_lahir.toUpperCase()}
												</td>
												<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
													{item.tanggal_lahir}
												</td>
												<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
													{item.provinsi.toUpperCase()}
												</td>
												<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
													{item.kabupaten.toUpperCase()}
												</td>
												<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
													{item.kecamatan.toUpperCase()}
												</td>
												<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
													{item.desa.toUpperCase()}
												</td>
												<td className="text-sm text-gray-900  text-center font-light px-6 py-4 whitespace-nowrap">
													{item.dusun.toUpperCase()}
												</td>
												<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
													{item.pendidikan_terakhir.toUpperCase()}
												</td>
												<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
													{item.pengalaman_kerja.toUpperCase()}
												</td>
												<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
													<a href={`${item.url}`}>
														<BsFileEarmarkPdf color="red" />
													</a>
												</td>

												<td className="text-sm text-gray-900 text-center font-light px-2 py-4">
													<div className="flex justify-center items-center gap-5">
														<TooltipComponent
															content="Hapus"
															position="TopCenter">
															<span
																className="hover:cursor-pointer"
																onClick={() => {
																	if (
																		window.confirm(
																			"Apakah Anda Yakin Ingin Menghapus Anggota?"
																		)
																	)
																		deleteUser(item.id);
																}}>
																<MdOutlineDeleteOutline color="red" />
															</span>
														</TooltipComponent>
														<span
															className="hover:cursor-pointer"
															onClick={() => setOpen(true)}>
															<BsPencilSquare color="green" />
														</span>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			)}
			<div className="flex m-auto justify-center md:mt-12 mt-8 items-center ">
				<ul className="inline-flex w-full -space-x-px">
					<li>
						<a
							href="#"
							className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
							Previous
						</a>
					</li>
					<li>
						<a
							href="#"
							className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
							1
						</a>
					</li>
					<li>
						<a
							href="#"
							className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
							2
						</a>
					</li>
					<li>
						<a
							href="#"
							className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
							3
						</a>
					</li>
					<li>
						<a
							href="#"
							className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
							4
						</a>
					</li>
					<li>
						<a
							href="#"
							className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
							5
						</a>
					</li>
					<li>
						<a
							href="#"
							className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
							Next
						</a>
					</li>
				</ul>
			</div>
		</>
	);
};

export default TableData;
