import React from "react";
import { customersData, customersGrid } from "../data/dummy";
import { Footer, Header } from "../components";
import Children from "../layout/Children";
import TableAllData from "../components/table/TableAllData";

const Costumers = () => {
	return (
		<Children>
			<div className=" md:m-10 min-h-screen p-5 bg-white rounded-xl dark:bg-secondary-dark-bg">
				<Header category="Halaman" title="Pelamar Welder" />
				<TableAllData />
				<Footer />
			</div>
		</Children>
	);
};

export default Costumers;
