import React from "react";
import { CartHeader, Footer, Header, Pie as PieChart } from "../../components";
import Children from "../../layout/Children";
import FormInputPerusahaan from "../../components/form/FormInputPerusahaan";

const Pie = () => (
	<Children>
		<div className=" md:m-10 min-h-screen p-5 bg-white rounded-xl dark:bg-secondary-dark-bg">
			<Header category="Halaman" title="Tambah Perusahaan" />
			<FormInputPerusahaan />
			<Footer />
		</div>
	</Children>
);

export default Pie;
