import React, { useEffect } from "react";
import { CartHeader, Footer, Header } from "../../components";
import { useStateContext } from "../../context/ContextProvider";
import Children from "../../layout/Children";
import FormInputPosisi from "../../components/form/FormInputPosisi";
import { useNavigate } from "react-router-dom";

const Area = () => {
	const { currentMode, roles, token } = useStateContext();
	const navigate = useNavigate();
	useEffect(() => {
		if (!token) {
			navigate("/");
		}
		if (roles !== "admin") {
			navigate("/");
		}
	}, []);
	return (
		<Children>
			<div className=" md:m-10 min-h-screen p-5 bg-white rounded-xl dark:bg-secondary-dark-bg">
				<Header category="Halaman" title="Tambah Posisi" />
				<FormInputPosisi />
				<Footer />
			</div>
		</Children>
	);
};

export default Area;
