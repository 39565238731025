import React from "react";
import { Footer, Header } from "../components";
import { useStateContext } from "../context/ContextProvider";
import Children from "../layout/Children";
import TableData from "../components/table/TablePemilih";

const Kanban = () => {
	const { currentMode } = useStateContext();
	return (
		<Children>
			<div className=" md:m-10 min-h-screen p-5 bg-white rounded-xl dark:bg-secondary-dark-bg">
				<Header title="Pelamar Security" category="Data" />
				<TableData />
				<Footer />
			</div>
		</Children>
	);
};

export default Kanban;
