import React, { useEffect, useState } from "react";
import { employeesGrid, employeesData } from "../data/dummy";
import { Footer, Header } from "../components";
import Children from "../layout/Children";
import TableData from "../components/table/TablePemilih";
import { useStateContext } from "../context/ContextProvider";
import { useNavigate } from "react-router-dom";

const Employees = () => {
	const { roles, token } = useStateContext();
	const navigate = useNavigate();
	useEffect(() => {
		if (!token) {
			navigate("/");
		}
		if (roles !== "admin") {
			navigate("/");
		}
	}, []);

	return (
		<Children>
			<div className=" md:m-10 min-h-screen p-5 bg-white rounded-xl dark:bg-secondary-dark-bg">
				<Header category="Halaman" title="Pegawai" />
				<TableData />
				<Footer />
			</div>
		</Children>
	);
};

export default Employees;
