import { ToastContainer, toast } from "react-toastify";

export const notifySukses = () =>
	toast("Berhasil Menambahkan Data!", {
		position: "top-right",
		autoClose: 2000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	});

export const notifyGagal = () =>
	toast.error("Gagal Menambahkan Data!", {
		position: "top-right",
		autoClose: 2000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	});
