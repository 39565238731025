import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { GiMiningHelmet } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { LineChart, Header, Footer } from "../../components";
import { useStateContext } from "../../context/ContextProvider";
import Children from "../../layout/Children";
import { TbBuilding } from "react-icons/tb";
import { GrUserWorker } from "react-icons/gr";
import { HiOutlineUserGroup } from "react-icons/hi";

const Line = () => {
	const [total, setTotal] = useState();
	const [data, setData] = useState([]);
	let [state, setState] = useState("");
	let [totalPelamar, setTotalPelamar] = useState([]);
	const { token, roles, currentColor } = useStateContext();
	const getData = async () => {
		const response = await axios.get("https://api.ctk-kiba.com/api/posisi", {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		setData(response.data);
	};

	const navigate = useNavigate();
	useEffect(() => {
		if (!token) {
			navigate("/");
		}
		if (roles !== "admin") {
			navigate("/");
		}
		getData();
	}, []);

	console.log(data);

	return (
		<Children>
			<div className=" md:m-10 min-h-screen p-5 bg-white rounded-xl dark:bg-secondary-dark-bg">
				<Header category="Data" title="Posisi Yang Tersedia" />
				<div className="mt-16">
					<div className="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4">
						{data.map((item, index) => (
							<div
								key={index}
								onClick={() => navigate(`/tabledimanis/${item.id}`)}
								className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
								<div className="bg-clip-border mx-4 font-bold rounded-xl overflow-hidden bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-blue-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
									{item.id}
								</div>
								<div className="p-4 text-right">
									<p className="block antialiased font-sans leading-normal font-semibold text-blue-600">
										<span className="text-black uppercase font-bold">
											{item.nama_posisi}
										</span>
									</p>
									<span
										style={{ color: currentColor }}
										className={`text-blue-500 tracking-normal font-sans text-lg font-bold leading-snug`}>
										{item.pelamarCount} Pelamar
									</span>
								</div>
								<div
									onClick={() => navigate(`/tabledimanis/${item.id}`)}
									className="border-t hover:cursor-pointer border-blue-gray-50 p-4">
									<div className="flex justify-between items-center ">
										<p className="block antialiased font-sans text-base leading-relaxed font-bold text-blue-gray-600">
											Lihat Data
										</p>
										<AiOutlineArrowRight />
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				<Footer />
			</div>
		</Children>
	);
};

export default Line;
