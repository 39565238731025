import React, { useEffect, useState } from "react";
import { Footer, Header } from "../components";
import Children from "../layout/Children";
import { useStateContext } from "../context/ContextProvider";
import { useNavigate } from "react-router-dom";
import TableUserPelamar from "../components/table/TableUserPelamar";

const UserPelamar = () => {
	const { roles, token } = useStateContext();
	const navigate = useNavigate();
	useEffect(() => {
		if (!token) {
			navigate("/");
		}
		if (roles !== "admin") {
			navigate("/");
		}
	}, []);

	return (
		<Children>
			<div className=" md:m-10 min-h-screen p-5 bg-white rounded-xl dark:bg-secondary-dark-bg">
				<Header title="User Pelamar Keseluruhan" category="Data" />
				<TableUserPelamar />
				<Footer />
			</div>
		</Children>
	);
};

export default UserPelamar;
