import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useStateContext } from "../../context/ContextProvider";
import { CartHeader, Footer } from "../../components";
import Children from "../../layout/Children";
import { useLocation } from "react-router-dom";

const Pyramid = () => {
	const location = useLocation();
	const { currentMode } = useStateContext();
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}
	pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

	return (
		<div className="w-full">
			<Document
				onLoadSuccess={onDocumentLoadSuccess}
				file={{
					url: `${location.state.url}`,
				}}>
				<Page pageNumber={pageNumber} />
			</Document>
		</div>
	);
};

export default Pyramid;
