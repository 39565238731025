import Cookies from "js-cookie";
import React, { createContext, useContext, useEffect, useState } from "react";

const StateContext = createContext();

const initialState = {
	chat: false,
	cart: false,
	userProfile: false,
	notification: false,
};

export const ContextProvider = ({ children }) => {
	const [screenSize, setScreenSize] = useState(undefined);
	const [currentColor, setCurrentColor] = useState("#054A91");
	const [currentMode, setCurrentMode] = useState("Light");
	const [themeSettings, setThemeSettings] = useState(false);
	const [activeMenu, setActiveMenu] = useState(true);
	const [isClicked, setIsClicked] = useState(initialState);
	const [leangue, setLeangue] = useState("en");
	const [user, setUser] = useState("");
	const [token, setToken] = useState(Cookies.get("acces_token"));
	const [roles, setRoles] = useState(Cookies.get("access"));

	const setMode = (e) => {
		setCurrentMode(e.target.value);
		localStorage.setItem("themeMode", e.target.value);
	};
	const setColor = (color) => {
		setCurrentColor(color);
		localStorage.setItem("colorMode", color);
	};

	const handleClick = (clicked) =>
		setIsClicked({ ...initialState, [clicked]: true });

	return (
		<StateContext.Provider
			value={{
				currentColor,
				currentMode,
				activeMenu,
				user,
				screenSize,
				token,
				setToken,
				roles,
				setRoles,
				leangue,
				themeSettings,
				initialState,
				isClicked,
				handleClick,
				setScreenSize,
				setIsClicked,
				setUser,
				setLeangue,
				setActiveMenu,
				setCurrentColor,
				setCurrentMode,
				setMode,
				setColor,
				setThemeSettings,
			}}>
			{children}
		</StateContext.Provider>
	);
};

export const useStateContext = () => useContext(StateContext);
