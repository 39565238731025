import React from "react";
import { MdOutlineCancel } from "react-icons/md";
import { Button } from ".";
import { userProfileData } from "../data/dummy";
import { useStateContext } from "../context/ContextProvider";
import avatar from "../data/foto.JPG";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

const UserProfile = () => {
	const { currentColor, handleClick } = useStateContext();
	const logout = () => {
		Cookies.remove("acces_token");
		Cookies.remove("username");
		Cookies.remove("access");
		window.location.href = "/";
	};

	return (
		<>
			<div
				onClick={() => handleClick("")}
				className=" fixed z-[9999] top-12 ml-12 right-[1px] drop-shadow-lg right  p-8 rounded-lg w-74">
				<div className="flex justify-between gap-2">
					<div onClick={logout}>
						<Button
							color="white"
							bgColor={currentColor}
							text="Logout"
							borderRadius="10px"
							width="full"
						/>
					</div>
					<div className="text-red-500 -mt-2 hover:cursor-pointer">
						<MdOutlineCancel />
					</div>
				</div>
			</div>
		</>
	);
};

export default UserProfile;
